<template>
  <div id="app">
    <van-nav-bar title="故障维修" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-tabs v-model:active="active" :ellipsis="false" @click="clickTabs">
      <van-tab>
        <template #title>全部 ({{ totalCm }})</template>
      </van-tab>
      <van-tab>
        <template #title>待维修 ({{ todoCm }})</template>
      </van-tab>
      <van-tab>
        <template #title>维修中 ({{ doingCm }})</template>
      </van-tab>
      <van-tab>
        <template #title>驳回 ({{ backCm }})</template>
      </van-tab>
      <van-tab>
        <template #title>待保全审核 ({{ endCm }})</template>
      </van-tab>
<!--      <van-tab>-->
<!--        <template #title>待班长确认 ({{ toProductionCm }})</template>-->
<!--      </van-tab>-->
      <van-tab>
        <template #title>完成 ({{ doneCm }})</template>
      </van-tab>
    </van-tabs>
    <van-search v-model="mobileSearch" placeholder="请输入搜索关键词" @search="onSearch" class="asset-search" />
    <div class="common-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadMore" offset="1" :immediate-check="false">
          <van-cell v-for="(item, index) in cmList" :key="index">
            <div @click="goUrls(`/workorder/cm_detail/${item.id}`)">
              <div class="title">
                <div class="fl tit">{{ item.name }}<br>{{ item.maintain_code }}</div>
                <div class="fr status">
                  <div v-if="item.status === -1">
                    <div class="status0"><span></span>审核驳回</div>
                  </div>
                  <div v-if="item.status === 0">
                    <div class="status1"><span></span>待维修</div>
                  </div>
                  <div v-if="item.status === 1">
                    <div class="status2"><span></span>维修中</div>
                  </div>
                  <div v-if="item.status === 2">
                    <div class="status2"><span></span>待保全审核</div>
                  </div>
<!--                  <div v-if="item.status === 3">-->
<!--                    <div class="status2"><span></span>待生产确认</div>-->
<!--                  </div>-->
                  <div v-if="item.status === 3">
                    <div class="status2"><span></span>维修完成</div>
                  </div>
                </div>
              </div>
              <div class="detail">
                <div class="info">是否停产：{{ item.is_stop }}</div>
                <div class="info">维修检测：{{ item.tag }}</div>
                <div class="info">发现时间：{{ item.create_time }}</div>
                <div class="info">完成时间：{{ item.end_time }}</div>
              </div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'
import API from '@/api/workorder/cm'
export default {
  inject: ['goUrl'],
  data() {
    return {
      active: 0,
      query: { page: 0, limit: 15 },
      mobileSearch: '',
      cmList: [],
      loading: false,
      finished: false,
      refreshing: false,
      finishLoading: false,
      totalCm: 0,
      todoCm: 0,
      doingCm: 0,
      backCm: 0,
      endCm: 0,
      toProductionCm: 0,
      doneCm: 0
    }
  },
  created() {
    this.index()
  },
  methods: {
    index() {
      this.finishLoading = false
      this.query.page += 1
      if (this.refreshing) {
        this.cmList = []
        this.refreshing = false
      }
      return API.cmList(this.query).then(res=> {
        this.totalCm = res.data.m_total
        this.todoCm = res.data.todo
        this.backCm = res.data.back
        this.doingCm = res.data.doing
        this.endCm = res.data.end
        this.toProductionCm = res.data.toProduction
        this.doneCm = res.data.done
        if (res.data.error_code === 888) {
          Toast('暂无工单')
          this.cmList = []
        } else {
          this.cmList.push(...res.data.data)
          this.loading = false
          // if (this.active === 1) {
          //   // 待维修
          //   this.cmList.length >= this.todoCm ? (this.finished = true) : (this.finished = false)
          // } else if (this.active === 2) {
          //   // 维修中
          //   this.cmList.length >= this.doingCm ? (this.finished = true) : (this.finished = false)
          // } else if (this.active === 3) {
          //   // 驳回
          //   this.cmList.length >= this.backCm ? (this.finished = true) : (this.finished = false)
          // } else if (this.active === 4) {
          //   // 待保全
          //   this.cmList.length >= this.endCm ? (this.finished = true) : (this.finished = false)
          // } else if (this.active === 5) {
          //   // 待生产
          //   this.cmList.length >= this.toProductionCm ? (this.finished = true) : (this.finished = false)
          // } else if (this.active === 6) {
          //   // 完成
          //   this.cmList.length >= this.doneCm ? (this.finished = true) : (this.finished = false)
          // } else {
          //   // 全部
          //   console.log(this.totalCm)
          //   this.cmList.length >= this.totalCm ? (this.finished = true) : (this.finished = false)
          // }

          if (this.active === 1) {
            // 待维修
            this.cmList.length >= this.todoCm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 2) {
            // 维修中
            this.cmList.length >= this.doingCm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 3) {
            // 驳回
            this.cmList.length >= this.backCm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 4) {
            // 待保全
            this.cmList.length >= this.endCm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 5) {
            // 完成
            this.cmList.length >= this.doneCm ? (this.finished = true) : (this.finished = false)
          } else {
            // 全部
            this.cmList.length >= this.totalCm ? (this.finished = true) : (this.finished = false)
          }
        }
      }).finally(() => {
        this.finishLoading = true
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    onSearch() {
      this.query.mobileSearch = this.mobileSearch
      this.query.page = 0
      this.cmList = []
      this.index()
    },
    onRefresh() {
      this.cmList = []
      this.query.page = 0
      this.finished = true
      this.index()
    },
    loadMore() {
      console.log('加载了')
      if (this.finishLoading === true) {
        this.index()
      }
    },
    goUrls(url) {
      this.goUrl(url)
    },
    clickTabs(name) {
      console.log(name)
      this.cmList = []
      this.query.page = 0
      this.finished = true
      let status = null
      // 全部 待维修 维修中 驳回  待保全审核 待班长确认 完成
      // if (name === 1) {
      //   status = 0
      // } else if (name === 2) {
      //   status = 1
      // } else if (name === 3) {
      //   status = -1
      // } else if (name === 4) {
      //   status = 2
      // } else if (name === 5) {
      //   status = 3
      // } else if (name === 6) {
      //   status = 4
      // } else {
      //   status = ''
      // }
      // 全部 待维修 维修中 驳回  待保全审核 完成
      if (name === 1) {
        status = 0
      } else if (name === 2) {
        status = 1
      } else if (name === 3) {
        status = -1
      } else if (name === 4) {
        status = 2
      } else if (name === 5) {
        status = 3
      } else {
        status = ''
      }
      this.query.status = status
      this.index()
    }
  }
}
</script>
<style>
.asset-search{
  background-color: #ebeef5;
}
.status0{
  color: blue;
}
.status0 span{
  background-color: blue !important;
}
.status1{
  color: orange;
}
.status1 span{
  background-color: orange !important;
}
.status2{
  color: green;
}
.status2 span{
  background-color: green !important;
}
</style>
